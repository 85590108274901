@import "variables";

.landing {
    display: flex;
    flex-direction: row;

    background-color: $color2;

    h1 {
        width: 100%;
        text-align: center;
    }


    div[class=main] {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 4.5;
    }

    div[class=menu] {
        flex: 1;
    }
}