@import "variables";

.landing {
    display: flex;
    flex-direction: row;

    & > div {
        flex: 1;
    }
}

.menu {
    background-color: $color1;
    min-height: 100vh;
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 10px;

    box-sizing: border-box;

    & > div {
        box-sizing: border-box;
        width: 100%;
        max-width: 270px;
    }
    
}

.settings {
    h2, h4 {
        text-align: center;
    }

    div[class=selection] {
        $margin: 15px;
        margin: {
            top: $margin;
            bottom: $margin;
        }

        div {
            margin-bottom: 3px;
            
            label {
                margin-left: 3px;
            }
        }
    }

    input[type=number] {
        width: 100%;
        box-sizing: border-box;
    }

    div[class=buttonContainer] {
        margin: {
            top: 10px;
            bottom: 10px;
        }
        display: flex;
        justify-content: center;
    }

}

.displaySettings {
    margin-top: 20px;

    label {
        margin: {
            top: 10px;
            bottom: 10px;
        }
    }

    div[class=buttonContainer] {
        display: flex;
        justify-content: space-evenly;
        
        button {
            $dimensions: 30px;
            width: $dimensions;
            height: $dimensions;
            border-radius: 100%;
        }
    }
}

.leaderboard {
    h2 {
        text-align: center;
        padding: 5px;
        background-color: $color6;
    }

    div[class=selection] {
        display: flex;
        flex-direction: row;
        
        div {
            flex: 1;
            padding: 5px;
            text-align: center;
            user-select: none;
            cursor: pointer;
            background-color: $color5;

            &:nth-child(2n) {
                background-color: $color2;
            }
        }
    }
    
    div[class=scores] {
        & > div {
            display: flex;
            flex-direction: row;
            
            padding: 5px;
            
            background-color: $color5;
            
            &:nth-child(2n) {
                background-color: $color2;
            }
            
            & > div {
                flex: 1;
                overflow: hidden;
            }
        
            &[class=header] {
                background-color: $color6;
            }
        }
    }
}