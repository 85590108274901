.minesweeper {
    max-height: 90vh;

    display: flex;
    justify-content: center;
    align-items: center;

    & > div[class=gameContainer] {
        margin: 10px;
        max-width: 1000px;
        background-color: #c0c0c0;
        padding: 10px;
    }
}

.playingField {
    display: grid;
    
    grid-template-columns: repeat(var(--width), var(--size));

    cursor: pointer;

    border: 5px inset;

    user-select: none;
    -webkit-user-drag: none;
    
    img {
        height: 100%;
        width: 100%;

        user-select: none;
        -webkit-user-drag: none;
    }
}

.gameInformation {
    display: flex;
    justify-content: space-between;

    border: 3px inset;

    height: 10vw;
    max-height: 75px;
    
    div {
        margin: 8px;
        border: 2px inset;

        img {
            height: 100%;
            user-select: none;
            -webkit-user-drag: none;
        }
    }

    div[class=smileyContainer] {
        border: 2px solid #808080;

        cursor: pointer;
    }

    margin-bottom: 10px;
}

.scoreSubmit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    
    background-color: rgba(0, 0, 0, 0.5);

    input[type=text] {
        width: 100%;
        max-width: 200px;
        margin: {
            top: 10px;
            bottom: 10px;
        }
    }

    div[class=buttonContainer] {
        width: 100%;
        max-width: 200px;
        display: flex;
        justify-content: space-evenly;
    }
}