@import "variables";

:root {
    font-family: Arial, Helvetica, sans-serif;
}

h1, h2, h3, h4 {
    color: $color3;
    margin: 0;
}

h1 {
    font-size: 8ch;
    margin-bottom: 14px;

    @media (max-width: $breakingPointPhone) {
        font-size: 12vw;
    }
}

h2 {
    @media (max-width: $breakingPointPhone) {
        font-size: 5vw;
    }
}

input[type=text], input[type=number] {
    padding: 3px;
    
    border: {
        color: $color4;
        radius: $borderRadius;
        style: solid;
    }
}

button {
    padding: 5px;
    font-weight: 700;
    color: $color1;
    background-color: $color3;

    cursor: pointer;
    
    border: {
        color: $color4;
        radius: 10px;
        width: 0;
    }
}

a {
    text-decoration: none;
}