// breaking point
$breakingPointPhone: 600px;
$breakingPointSmallScreen: 800px;

// colors
$color1: #F78D50;
$color2: #F9AD81;
$color3: #a3ddff;
$color4: #50BAF7;
$color5: #f5b996;
$color6: #dd763a;

// other
$borderRadius: 3px;